exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-galleries-index-js": () => import("./../../../src/pages/galleries/index.js" /* webpackChunkName: "component---src-pages-galleries-index-js" */),
  "component---src-pages-galleries-justyna-arkadiusz-js": () => import("./../../../src/pages/galleries/justyna-arkadiusz.js" /* webpackChunkName: "component---src-pages-galleries-justyna-arkadiusz-js" */),
  "component---src-pages-galleries-karolina-michal-js": () => import("./../../../src/pages/galleries/karolina-michal.js" /* webpackChunkName: "component---src-pages-galleries-karolina-michal-js" */),
  "component---src-pages-galleries-laura-stephen-js": () => import("./../../../src/pages/galleries/laura-stephen.js" /* webpackChunkName: "component---src-pages-galleries-laura-stephen-js" */),
  "component---src-pages-galleries-weronika-matthew-js": () => import("./../../../src/pages/galleries/weronika-matthew.js" /* webpackChunkName: "component---src-pages-galleries-weronika-matthew-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-love-notes-js": () => import("./../../../src/pages/love-notes.js" /* webpackChunkName: "component---src-pages-love-notes-js" */),
  "component---src-pages-meet-kasia-js": () => import("./../../../src/pages/meet-kasia.js" /* webpackChunkName: "component---src-pages-meet-kasia-js" */),
  "component---src-pages-wedding-services-js": () => import("./../../../src/pages/wedding-services.js" /* webpackChunkName: "component---src-pages-wedding-services-js" */)
}

